<template>
  <v-app>
    <v-main>
      <div class="fill-height mx-0 arvrezinhas-bg" style="padding: 2% 10%">
        <div class="d-flex justify-space-between align-center">
          <div>
            <v-img
              src="../../assets/xbusiness_logo.png"
              class="logo-xbusiness"
            ></v-img>
          </div>

          <div>
            <v-img src="../../assets/clima.png"></v-img>
          </div>
        </div>
        <div class="mt-12 pt-12 d-flex justify-space-between">
          <div>
            <span class="text-h5">Curitiba - PR</span> <br />
            <span class="text-h4 font-weight-bold xbColor--text">
              {{ data_evento }}
            </span>
            <div class="mt-16">
              <span class="text-h5">Já estamos com:</span> <br />
              <div class="d-flex align-center justify-space-between">
                <v-card
                  v-if="new Date(dia_atual) > new Date('2024/03/01')"
                  round
                  flat
                  outlined
                  width="115px"
                  height="115px"
                  class="d-flex mr-4 justify-center align-center mt-6"
                >
                  <div class="text-center timer--text">
                    <div class="text-h4 font-weight-black">
                      <span>{{ dias_passados_evento }}</span>
                    </div>

                    <div class="text-h5 font-weight-light mt-2">Dia(s)</div>
                  </div>
                </v-card>

                <v-card
                  round
                  flat
                  outlined
                  width="115px"
                  height="115px"
                  class="d-flex mr-4 justify-center align-center mt-6"
                >
                  <div class="text-center timer--text">
                    <div class="text-h4 font-weight-black">
                      {{ formattedElapsedTimeHora }}
                    </div>

                    <div class="text-h5 font-weight-light mt-2">Hora(s)</div>
                  </div>
                </v-card>

                <v-card
                  round
                  flat
                  outlined
                  width="115px"
                  height="115px"
                  class="d-flex mr-4 justify-center align-center mt-6"
                >
                  <div class="text-center timer--text">
                    <div class="text-h4 font-weight-black">
                      {{ formattedElapsedTimeMinuto }}
                    </div>

                    <div class="text-h5 font-weight-light mt-2">Minutos</div>
                  </div>
                </v-card>

                <v-card
                  round
                  flat
                  outlined
                  width="115px"
                  height="115px"
                  class="d-flex mr-4 justify-center align-center mt-6"
                >
                  <div class="text-center timer--text">
                    <div class="text-h4 font-weight-black">
                      {{ formattedElapsedTimeSegundo }}
                    </div>

                    <div class="text-h5 font-weight-light mt-2">Segundos</div>
                  </div>
                </v-card>
              </div>
              <div class="d-flex justify-end mr-4">
                <span class="text-h5 mt-2"> de evento. </span>
              </div>
            </div>
          </div>

          <div>
            <div class="">
              <span class="text-h4">Até agora emitimos</span> <br /><br />
              <span class="text-h3 font-weight-bold xbColor--text">
                {{ qtd_co2 }}
                <span class="font-weight-regular text-h4">
                  toneladas de CO2
                </span>
              </span>
            </div>
            <div class="mt-16">
              <span class="text-h4">Para neutralizar vamos plantar</span>
              <br /><br />
              <span class="text-h3 font-weight-bold xbColor--text">
                {{ qtd_arvore }}
                <span class="font-weight-regular text-h4">árvores</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { format, parseISO, differenceInMilliseconds } from "date-fns";
import { ptBR } from "date-fns/locale";

export default {
  name: "Co2Desktop",

  data() {
    return {
      data_evento: format(parseISO("2024-03-01"), "dd 'de' MMMM", {
        locale: ptBR,
      }),
      elapsedTime: 0,
      elapsedTime2: 0,
      timer: undefined,
      timer2: undefined,
      data_inicio: new Date("2024", "03", "01", "07", "00", "00"),
      data_inicio2: new Date("2024", "03", "01", "00", "00", "00"),
      dia_atual: format(new Date(), "yyyy/MM/dd"),
      qtd_co2: 0,
      qtd_arvore: 0,
      dias_passados_evento: 0,
    };
  },

  watch: {
    formattedElapsedTimeHora() {
      this.calculaCO2();
      this.calculaArvore();
    },

    formattedElapsedTime2() {
      if (this.formattedElapsedTime2 == "23:59:59") {
        this.elapsedTime = 0;

        this.stopTimer();
      }
      if (this.formattedElapsedTime2 == "00:00:00") {
        this.dia_atual = format(new Date(), "yyyy/MM/dd");
      }

      if (this.formattedElapsedTime2 == "07:00:00") {
        this.getDifference();
        this.startTimer();
      }
    },
  },

  computed: {
    formattedElapsedTimeHora() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toISOString();
      return utc.substr(11, 2);
    },
    formattedElapsedTimeMinuto() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toISOString();
      return utc.substr(14, 2);
    },
    formattedElapsedTimeSegundo() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toISOString();
      return utc.substr(17, 2);
    },
    formattedElapsedTime() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toISOString();
      return utc.substr(11, 8);
    },
    formattedElapsedTime2() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime2 / 1000);
      const utc = date.toISOString();
      return utc.substr(11, 8);
    },
  },

  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    },
    startTimer2() {
      this.timer2 = setInterval(() => {
        this.elapsedTime2 += 1000;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },

    dataEvento() {
      let inicio_evento = new Date("2024-03-01");
      let data_atual = new Date();
      if (data_atual > inicio_evento) {
        this.data_evento = format(new Date(), "dd 'de' MMMM", {
          locale: ptBR,
        });
      }
      if (data_atual > new Date("2024-03-03")) {
        this.data_evento = format(parseISO("2024-03-03"), "dd 'de' MMMM", {
          locale: ptBR,
        });
      }
    },

    getDifference() {
      const result = differenceInMilliseconds(new Date(), this.data_inicio);
      this.elapsedTime = result;
    },

    calculaCO2() {
      let soma_dias = this.dias_passados_evento * 14 * 0.2203001;
      let calc = Number(this.formattedElapsedTimeHora) * 0.2203001;
      this.qtd_co2 =
        this.dias_passados_evento == 3 ? soma_dias : calc + soma_dias;
      this.qtd_co2 = Math.round((this.qtd_co2 + Number.EPSILON) * 100) / 100;
    },

    calculaArvore() {
      let calc = Number(this.formattedElapsedTimeHora) * 1.0199080552;
      let soma_dias = this.dias_passados_evento * 14 * 1.0199080552;
      this.qtd_arvore =
        this.dias_passados_evento == 3 ? soma_dias : calc + soma_dias;
      this.qtd_arvore = Math.ceil(this.qtd_arvore);
    },

    calculaDiasPassados() {
      let hoje = new Date();

      if (hoje > new Date("2024/03/01 23:59:59")) {
        this.dias_passados_evento = 1;
      }
      if (hoje > new Date("2024/03/02 23:59:59")) {
        this.dias_passados_evento = 2;
      }
      if (hoje > new Date("2024/03/03 23:59:59")) {
        this.dias_passados_evento = 3;
      }
    },
  },

  mounted() {
    this.dataEvento();
    this.calculaDiasPassados();
    const result2 = differenceInMilliseconds(new Date(), this.data_inicio2);
    this.elapsedTime2 = result2;

    this.getDifference();
    if (
      this.formattedElapsedTime2 >= "07:00:00" &&
      this.formattedElapsedTime2 <= "23:59:59" &&
      new Date(this.dia_atual) >= new Date("2024/03/01")
    ) {
      this.startTimer();
    } else {
      this.elapsedTime = 0;
    }
    this.startTimer2();
    this.calculaCO2();
    this.calculaArvore();
  },
};
</script>

<style lang="scss" scoped>
.arvrezinhas-bg {
  background-image: url(../../assets/plano_de_fundo.png);
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: 100% 100%;
}
.logo-xbusiness {
  width: 250px;
}
</style>
